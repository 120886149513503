import React, { Component } from 'react';
import { ZougglaScan } from '@zouggla/zouggla-react-sdk';
//import { MyModal } from "../lib/mymodal.js";
import '../App.css';
import { Redirect } from 'react-router';
import autoBind from 'react-autobind';

class Home extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoggedIn: false,
      q1: true,
      //      useProdStage: true,
      apiKey: 'II1tMbCzyv5wgcIPgfpVs9LcNLvWWCwX1S39Cpfx',
      stageLevel: 'prod'
    };
  }
  handleReturn = arg => {
    this.setState({ isLoggedIn: true, jwttoken: arg });
  };
  whenScanned() {
    console.log('scanned it');
    this.setState({ q1: false });
  }
  render() {
    if (this.state.isLoggedIn) {
      return (
        <Redirect
          push
          to={{
            pathname: '/welcome',
            state: { jwttoken: this.state.jwttoken }
          }}
        />
      );
    }

    const loginpayload = {
      partner: 'sportmetrika',
      requestedFields: ['email']
    };
    return (
      <div className="App">
        <header className="App-header">
          <p>sportmetrika.com: SportMetrika was ahead of its time!</p>
          <ZougglaScan
            toshow={this.state.q1}
            type="loginv2"
            data={loginpayload}
            howtoreturn={this.handleReturn}
            apiKey={this.state.apiKey}
          />
          <div className="Znote">
            Sign in with <a href="http://www.zouggla.com">Zouggla</a>
          </div>
        </header>

        <div />
      </div>
    );
  }
}

export default Home;
